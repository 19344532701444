import dashboardSlice from "../slices/dashboardSlice";

export const {
  RequestDashboard,
  SuccessDashboard,
  FailureDashboard,
  RequestNewFile,
  SuccessNewFile,
  FailureNewFile,
  RequestQuickSubmit,
  SuccessQuickSubmit,
  FailureQuickSubmit,
  CurrentNewFile,
  RequestNewScript,
  SuccessNewScript,
  FailureNewScript,
  RequestDailySubmissions,
  SuccessDailySubmissions,
  FailureDailySubmissions,
  RequestSubmissionsList,
  SuccessSubmissionsList,
  FailureSubmissionsList,
  RequestAllSubmissions,
  SuccessAllSubmissions,
  FailureAllSubmissions,
  RequestMaliciousSubmissions,
  SuccessMaliciousSubmissions,
  FailureMaliciousSubmissions,
  RequestCleanSubmissionsState,
  SuccessCleanSubmissionsState,
  FailureCleanSubmissionsState,
  RequestSuspiciousSubmissionsState,
  SuccessSuspiciousSubmissionsState,
  FailureSuspiciousSubmissionsState,
  RequestMaliciousSubmissionsState,
  SuccessMaliciousSubmissionsState,
  FailureMaliciousSubmissionsState,
  SetSubmittedFile,
  CopyClipboard,
  RequestGroupVerdict,
  SuccessGroupVerdict,
  FailureGroupVerdict,
  RequestResubmit,
  SuccessResubmit,
  FailureResubmit,
  RequestSubmitFreerun,
  SuccessSubmitFreerun,
  FailureSubmitFreerun,
  RequestToggleFavorite,
  RequestCleanRunningTab,
  RequestNewUrl,
  SuccessNewUrl,
  FailureNewUrl,
  ResetUrlError,
  RequestSetUILogo,
  SuccessSetUILogo,
  FailureSetUILogo,
  RequestGetUILogo,
  SuccessGetUILogo,
  FailureGetUILogo,
  RequestSubmissionsListForStats,
  SuccessSubmissionsListForStats,
  FailureSubmissionsListForStats,
  RequestRunningCount,
  SuccessRunningCount,
  FailureRunningCount,
  RequestRunningSubmissions,
  SuccessRunningSubmissions,
  FailureRunningSubmissions,
  RequestSearchCompletedSubmissions,
  SuccessSearchCompletedSubmissions,
  FailureSearchCompletedSubmissions,
  RequestIntegrationSubmissionCount,
  SuccessIntegrationSubmissionCount,
  FailureIntegrationSubmissionCount,
  RequestDailyIntegrationSubmissionCount,
  SuccessDailyIntegrationSubmissionCount,
  FailureDailyIntegrationSubmissionCount,
  RequestIntegrationSubmissionState,
  SuccessIntegrationSubmissionState,
  FailureIntegrationSubmissionState,
  RequestSearchGroupSubmissions,
  SuccessSearchGroupSubmissions,
  FailureSearchGroupSubmissions,
  RequestStaticSubmit,
  SuccessStaticSubmit,
  FailureStaticSubmit,
  RequestVms,
  SuccessVms,
  FailureVms,
  RequestAptCount,
  SuccessAptCount,
  FailureAptCount,
  RequestMalwareNameCounts,
  SuccessMalwareNameCounts,
  FailureMalwareNameCounts,
  RequestIntegrationMalwareNameCounts,
  SuccessIntegrationMalwareNameCounts,
  FailureIntegrationMalwareNameCounts,
  RequestDeleteSubmissionFromList,
} = dashboardSlice.actions;
