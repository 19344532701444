import Filter from "../../Components/common/Table/Components/FilterTable";
import Score from "../../Pages/AnalysisReports/Content/Tabs/Overview/components/Score";
import ProgressLine from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/ProgressLine";
import SeverityStatusWrapper from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/SeverityStatusWrapper";
import SeverityStatus2 from "../../Components/PagesComponents/Dashboard/DashBoardTable/Table/components/SeverityStatus";
import Os from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/OS";
import GroupCounter, {
  GroupCounter2,
} from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/GroupCounter";
import User from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/User";
import Vm from "../../Components/PagesComponents/RunningAnalysis/Vm";
import File from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/File";
import Sender from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Sender";
import Verdict from "../../Components/common/Cells/Verdict/Verdict";
import NetworkTraffic from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkTraffic";
import Status from "../../Components/common/Cells/Status/Status";
import Local from "../../Components/PagesComponents/Settings/BackupCells/Backup Location/Local";
import RemoteUrl from "../../Components/PagesComponents/Settings/BackupCells/Remote URL/RemoteUrl";
import FileNamePrefix from "../../Components/PagesComponents/Settings/BackupCells/FileName Prefix/FileNamePrefix";
import Encrypt from "../../Components/PagesComponents/Settings/BackupCells/Encrypt/Encrypt";
import Estimation from "../../Components/PagesComponents/Settings/BackupCells/Estimation/Estimation";
import Action from "../../Components/PagesComponents/Settings/BackupCells/Action/Action";
import Severity from "../../Pages/AnalysisReports/Content/Tabs/Overview/components/Severity";
import Recipient from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Recipient";
import TabEvent from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/TabEvent";
import FilePath from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/FilePath";
import Time from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Time";
import Senderimap from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Senderimap";
import Id from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Id";
import Server from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Server";
import IntegrationAction from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/IntegrationAction";
import CompressedFileCount from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/CompressedFileCount";
import Categories from "../../Pages/AnalysisReports/Content/Tabs/Overview/components/Categories";
import Ttps from "../../Pages/AnalysisReports/Content/Tabs/Overview/components/Ttps";
import Favorite from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Favorite";
import Type from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Type";
import Secret from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/Secret";
import NetworkDetectionsSeverity from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkDetectionsSeverity";
import NetworkDetectionsTime from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkDetectionsTime";
import NetworkDetectionsUrl from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkDetectionsUrl";
import NetworkDetectionsAction from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkDetectionsAction";
import NetworkDetectionsClientIp from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkDetectionsClientIp";
import NetworkDetectionsServerIp from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkDetectionsServerIp";
import NetworkDetectionsFileName from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/NetworkDetectionsFileName";
import UrlsUrl from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/UrlsUrl";
import UrlsFileName from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/UrlsFileName";
import UrlsFileHash from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/UrlsFileHash";
import DomainsAndIpsDomain from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/DomainsAndIpsDomain";
import HostAndDomain from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/HostAndDomain";
import ThreeDots from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/ThreeDots";
import StaticResultsRunning from "../../Components/PagesComponents/Dashboard/DashBoardTable/components/StaticResultsRunning";

export const overview = [
  {
    Filter,
    Header: "Description",
    accessor: "description",
    className: "overview__table-block-description",
    sort: true,
  },
  {
    Filter,
    Cell: Categories,
    Header: "Categories",
    accessor: "categories",
    className: "overview__table-block-categories",
    sort: true,
  },
  {
    Filter,
    Cell: Ttps,
    Header: "Ttps",
    accessor: "ttp",
    className: "overview__table-block-ttps",
    sort: true,
  },
  {
    Filter,
    Cell: Severity,
    Header: "Severity",
    accessor: "severity",
    className: "overview__table-block-last-child",
    sort: true,
  },
  {
    Filter,
    Header: "Confidence",
    accessor: "confidence",
    className: "overview__table-block-last-child",
    sort: true,
  },
  {
    Filter,
    Header: "Weight",
    accessor: "weight",
    className: "overview__table-block-last-child",
    sort: true,
  },
];

export const overviewReport = [
  {
    Header: "Description",
    accessor: "description",
    className: "overview__table-block-description",
  },
  {
    Cell: Categories,
    Header: "Categories",
    accessor: "categories",
    className: "overview__table-block-categories",
  },
  {
    Cell: Ttps,
    Header: "Ttps",
    accessor: "ttp",
    className: "overview__table-block-ttps",
  },
  {
    Cell: Severity,
    Header: "Severity",
    accessor: "severity",
    className: "overview__table-block-last-child",
  },
  {
    Header: "Confidence",
    accessor: "confidence",
    className: "overview__table-block-last-child",
  },
  {
    Header: "Weight",
    accessor: "weight",
    className: "overview__table-block-last-child",
  },
];

export const snortsuricata = [
  {
    Filter,
    Header: "Source Ip",
    accessor: "srcIp",
    className: "snort-suricata__table-block-ip",
    sort: true,
  },

  {
    Filter,
    Header: "Destination IP",
    accessor: "destIp",
    className: "snort-suricata__table-block-ip",
    sort: true,
  },

  {
    Header: "Type",
    accessor: "protocol",
    className: "snort-suricata__table-block-protocol",
    sort: true,
  },

  {
    Header: "CN",
    accessor: "country_code",
    className: "snort-suricata__table-block-protocol",
    sort: true,
  },

  {
    Filter,
    Header: "Signature/Messages",
    accessor: "signature",
    className: "snort-suricata__table-block-signature",
    sort: true,
  },
];

export const dashboardHeader = [
  {
    Cell: Favorite,
    accessor: "favorite",
  },
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "User",
    accessor: "user",
    rowClassName: "dashboard-table_user",
    Cell: User,
    sort: true,
  },
  {
    Header: "Submit Time / Type",
    accessor: "time.time",
    Cell: Time,
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Count",
    accessor: "compressedFileCount",
    Cell: CompressedFileCount,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "groupCounter",
    Cell: GroupCounter2,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Progress",
    accessor: "progress",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
  {
    id: "staticResults",
    accessor: "staticResultLink",
    Cell: ThreeDots,
  },
];

export const agentHeader = [
  {
    Header: "Submit Time",
    accessor: "time.time",
    Cell: Time,
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const listPopupHeader = [
  {
    Filter: false,
    Header: "Type",
    accessor: "file.name",
    Cell: Type,
    sort: false,
  },
  {
    Filter: false,
    Header: "Network Traffic",
    accessor: "networkTraffic",
    Cell: NetworkTraffic,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    rowClassName: "dashboard-table_os",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    rowClassName: "dashboard-table_severity",
    sort: false,
  },
];

export const listConfigHeader = [
  {
    Filter: false,
    accessor: "file.hash",
    Cell: File,
    sort: false,
  },
  {
    Filter: false,
    accessor: "configServer",
    Cell: Server,
    sort: false,
  },
  {
    Filter: false,
    accessor: "Id",
    Cell: Id,
    sort: false,
  },
  {
    Filter: false,
    accessor: "IntegrationAction",
    Cell: IntegrationAction,
    sort: false,
    event: "delete",
  },
];

export const listConfigHeaderCstrike = [
  {
    Filter: false,
    accessor: "file.hash",
    Cell: File,
    sort: false,
  },
  {
    Filter: false,
    accessor: "configServer",
    Cell: Secret,
    sort: false,
  },
  {
    Filter: false,
    accessor: "IntegrationAction",
    Cell: IntegrationAction,
    sort: false,
    event: "delete",
  },
];

export const statisticsHeader = [
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "User",
    accessor: "user",
    rowClassName: "dashboard-table_user",
    Cell: User,
    sort: true,
  },
  {
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Submit Type",
    rowClassName: "dashboard-table_submit-type",
    accessor: "submitType",
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    Cell: GroupCounter,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Progress",
    accessor: "progress",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const statisticsListPopupHeader = [
  {
    Filter: false,
    Header: "Type",
    accessor: "file.name",
    Cell: Type,
    sort: false,
  },
  {
    Filter: false,
    Header: "Network Traffic",
    accessor: "networkTraffic",
    Cell: NetworkTraffic,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    rowClassName: "dashboard-table_os",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    rowClassName: "dashboard-table_severity",
    sort: false,
  },
];

export const emailHeader = [
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "Sender",
    accessor: "sender",
    Cell: Sender,
    sort: true,
  },
  {
    Filter,
    Header: "Recipients",
    accessor: "recipients",
    Cell: Recipient,
    sort: true,
  },
  {
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    Cell: GroupCounter,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Reported Time",
    accessor: "Reported Time",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const usbTransferHeader = [
  {
    Filter,
    Header: "USB Transfer Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter: false,
    Header: "USB Name",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "OS",
    accessor: "oS",
    Cell: Os,
    sort: false,
  },
  {
    Filter,
    Cell: ProgressLine,
    Header: "Progress",
    accessor: "progress",
    sort: true,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "State",
    accessor: "severity",
    sort: true,
  },
];

export const imapEmailHeader = [
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "Sender",
    accessor: "sender.email",
    Cell: Senderimap,
    sort: true,
  },
  {
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "groupCounter",
    Cell: GroupCounter2,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Reported Time",
    accessor: "progress",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const fileshareHeader = [
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "Full Path",
    accessor: "path",
    Cell: FilePath,
    sort: false,
  },
  {
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "groupCounter",
    Cell: GroupCounter2,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Reported Time",
    accessor: "progress",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const proxyHeader = [
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "Client User",
    accessor: "sender.name",
    Cell: Sender,
    sort: true,
  },
  {
    Filter,
    Header: "Client IP",
    accessor: "path",
    Cell: FilePath,
    sort: true,
  },
  {
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "groupCounter",
    Cell: GroupCounter2,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Reported Time",
    accessor: "progress",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const endpointHeader = [
  {
    Filter,
    Header: "Client User",
    accessor: "sender.name",
    Cell: Sender,
    sort: true,
  },
  {
    Filter,
    Header: "Hostname",
    accessor: "path",
    Cell: FilePath,
    sort: true,
  },
  {
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "groupCounter",
    Cell: GroupCounter2,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Reported Time",
    accessor: "progress",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const postfixEmailHeader = [
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "Sender",
    accessor: "sender.name",
    Cell: Sender,
    sort: true,
  },
  {
    Filter,
    Header: "Recipients",
    accessor: "recipients",
    Cell: Recipient,
    sort: true,
  },
  {
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "groupCounter",
    Cell: GroupCounter2,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Reported Time",
    accessor: "progress",
    sort: false,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: true,
  },
];

export const proxyPopupHeader = [
  {
    Filter: false,
    Header: "Type",
    accessor: "file.name",
    Cell: Type,
    sort: false,
  },
  {
    Filter: false,
    Header: "Network Traffic",
    accessor: "networkTraffic",
    Cell: NetworkTraffic,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    rowClassName: "dashboard-table_os",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    rowClassName: "dashboard-table_severity",
    sort: false,
  },
];

export const endpointPopupHeader = [
  {
    Filter: false,
    Header: "Type",
    accessor: "file.name",
    Cell: Type,
    sort: false,
  },
  {
    Filter: false,
    Header: "Network Traffic",
    accessor: "networkTraffic",
    Cell: NetworkTraffic,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    rowClassName: "dashboard-table_os",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    rowClassName: "dashboard-table_severity",
    sort: false,
  },
];

export const filesharePopupHeader = [
  {
    Filter: false,
    Header: "Type",
    accessor: "file.name",
    Cell: Type,
    sort: false,
  },
  {
    Filter: false,
    Header: "Network Traffic",
    accessor: "networkTraffic",
    Cell: NetworkTraffic,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    rowClassName: "dashboard-table_os",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    rowClassName: "dashboard-table_severity",
    sort: false,
  },
];

export const emailPopupHeader = [
  {
    Filter: false,
    Header: "Type",
    accessor: "file.name",
    Cell: Type,
    sort: false,
  },
  {
    Filter: false,
    Header: "Network Traffic",
    accessor: "networkTraffic",
    Cell: NetworkTraffic,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    rowClassName: "dashboard-table_os",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    rowClassName: "dashboard-table_severity",
    sort: false,
  },
];
export const postfixemailPopupHeader = [
  {
    Filter: false,
    Header: "Type",
    accessor: "file.name",
    Cell: Type,
    sort: false,
  },
  {
    Filter: false,
    Header: "Network Traffic",
    accessor: "networkTraffic",
    Cell: NetworkTraffic,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "Os",
    rowClassName: "dashboard-table_os",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    rowClassName: "dashboard-table_severity",
    sort: false,
  },
];

export const runningDashboardHeader = [
  {
    Filter,
    Header: "User",
    accessor: "user",
    Cell: User,
    rowClassName: "dashboard-table_user-run",
    sort: true,
  },
  {
    Header: "Submit Time / Type",
    accessor: "time.time",
    rowClassName: "dashboard-table_time-run",
    Cell: Time,
    sort: true,
  },
  {
    Header: <span style={{ display: "inline-block", width: "max-content" }}>S. Results</span>,
    accessor: "staticResultLink",
    rowClassName: "dashboard-table_static-run",
    Cell: StaticResultsRunning,
    sort: false,
  },
  {
    Filter,
    Header: "File Name",
    accessor: "file.name",
    rowClassName: "dashboard-table_link-run",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Count",
    accessor: "compressedFileCount",
    Cell: CompressedFileCount,
    sort: false,
  },
  {
    Filter: false,
    Header: "OS",
    accessor: "oS",
    Cell: Os,
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Progress",
    accessor: "progress",
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Status",
    accessor: "severity",
    sort: false,
  },
];

export const runningPopupHeader = [
  {
    Filter: false,
    Header: "File Name",
    accessor: "file.name",
    rowClassName: "custom-table__running-popup__file-name",
    sort: false,
  },
  {
    Filter: false,
    Cell: ProgressLine,
    Header: "Progress",
    accessor: "progress",
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "State / Verdict",
    accessor: "severity",
    sort: false,
  },
];

export const runningHeader = [
  {
    Filter: false,
    Header: "File Name",
    accessor: "file",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Header: "Analyser",
    accessor: "Analyser",
    sort: true,
  },
  {
    Filter: false,
    Header: "VM Connection",
    accessor: "Vm",
    Cell: Vm,
    sort: true,
  },
  {
    Filter,
    Header: "Submit Time",
    accessor: "time",
    sort: true,
  },
  {
    Filter,
    Cell: SeverityStatusWrapper,
    Header: "Status",
    accessor: "severity",
    sort: true,
  },
];

export const dashboardMaliciousHeader = [
  // {
  //   event: "copy",
  //   accessor: "tab-event",
  //   Cell: TabEvent,
  // },
  {
    Filter,
    Header: "User",
    accessor: "user",
    rowClassName: "dashboard-table_user",
    Cell: User,
    sort: true,
  },
  {
    Header: "Submit Time / Type",
    accessor: "time.time",
    Cell: Time,
    sort: true,
  },
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: true,
  },
  {
    Filter: false,
    Header: "Count",
    accessor: "compressedFileCount",
    Cell: CompressedFileCount,
    sort: false,
  },
  {
    Filter: false,
    Header: "Os",
    accessor: "groupCounter",
    Cell: GroupCounter2,
    sort: false,
  },
  {
    Filter,
    Cell: ProgressLine,
    Header: "Reported Time",
    accessor: "progress",
    sort: true,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "severity",
    sort: false,
  },
];

export const droppedFileList = [
  {
    Filter,
    Header: "Type",
    accessor: "type",
    className: "dropped-files__table-block-type",
    sort: true,
  },
  {
    Filter,
    Header: "File Name",
    accessor: "value",
    className: "dropped-files__table-block-th",
    sort: true,
  },
  {
    Filter,
    Header: "Details Preview",
    accessor: "details",
    sort: true,
  },
  {
    Filter,
    Header: "YARA",
    accessor: "yara",
    className: "dropped-files__table-block-last-child",
    sort: true,
  },
  {
    Filter,
    Header: "VirusTotal",
    accessor: "virustotal",
    className: "dropped-files__table-block-last-child",
    sort: true,
  },
];

export const avYara = [
  {
    Filter,
    Header: "File type",
    accessor: "fileType",
    sort: true,
  },
  {
    Filter,
    Header: "Threat Name",
    accessor: "threatName",
    sort: true,
  },
  {
    Filter,
    Header: "File Name",
    accessor: "fileName",
    sort: true,
  },
  {
    Filter,
    Header: "Verdict",
    accessor: "verdict",
    sort: true,
    Cell: Verdict,
  },
  {
    Filter: false,
    Header: "Actions",
    accessor: "actions",
    sort: true,
  },
];

export const yaraMatchesHeader = [
  {
    Filter,
    Header: "Ruleset Name",
    accessor: "rulesetName",
    sort: true,
  },
  {
    Filter,
    Header: "Rule Name",
    accessor: "ruleName",
    sort: true,
  },
  {
    Filter,
    Header: "Rule Description",
    accessor: "ruleDescription",
    sort: true,
  },
  {
    Filter,
    Header: "File type",
    accessor: "fileType",
    sort: true,
  },
  {
    Filter,
    Header: "File Name",
    accessor: "fileName",
    sort: true,
  },
  {
    Filter,
    Header: "Classification",
    accessor: "classification",
    sort: true,
  },
  {
    Filter,
    Header: "Score",
    accessor: "score",
    Cell: Score,
    sort: true,
  },
  {
    Filter: false,
    Header: "Actions",
    accessor: "actions",
    sort: true,
  },
];

export const settingsYaraRules = [
  {
    Filter,
    Header: "Name",
    accessor: "name",
    sort: true,
  },
  {
    Filter,
    Header: "File type",
    accessor: "fileType",
    sort: true,
  },
  {
    Filter,
    Header: "Content type",
    accessor: "contentType",
    sort: true,
  },
  {
    Filter,
    Header: "Modification Time",
    accessor: "modificationTime",
    sort: true,
  },
  {
    Filter,
    Header: "Size",
    accessor: "size",
    sort: true,
  },
  {
    Filter,
    Header: "Delete",
    accessor: "delete",
    sort: true,
  },
];
export const settingsGuestImages = [
  {
    Filter: false,
    Header: "Image Name",
    accessor: "name",
    sort: true,
  },
  {
    Filter: false,
    Header: "Version",
    accessor: "version",
    sort: true,
  },
  {
    Filter: false,
    Header: "Type",
    accessor: "type",
    sort: true,
  },
  {
    Filter: false,
    Header: "Status",
    accessor: "status",
    Cell: Status,
    sort: true,
  },
  {
    Filter: false,
    Header: "Action",
    accessor: "action",
    sort: false,
  },
];

export const settingsBackup = [
  {
    Filter: false,
    Header: "Backup Profile",
    accessor: "profile",
    sort: false,
  },
  {
    Filter: false,
    Header: "Backup Location",
    accessor: "location",
    Cell: Local,
    sort: false,
  },
  {
    Filter: false,
    Header: "Remote URL or Server Location",
    accessor: "remote_url",
    sort: false,
    Cell: RemoteUrl,
  },
  {
    Filter: false,
    Header: "File Name Prefix",
    accessor: "file_name",
    sort: false,
    Cell: FileNamePrefix,
  },
  {
    Filter: false,
    Header: "Encrypt?",
    accessor: "encrypt",
    sort: false,
    Cell: Encrypt,
  },
  {
    Filter: false,
    Header: "Estimation Backup",
    accessor: "estimation",
    sort: false,
    Cell: Estimation,
  },
  {
    Filter: false,
    Header: "Action",
    accessor: "backup",
    sort: false,
    Cell: Action,
  },
];

export const settings_RSYSLOG = [
  {
    Filter: false,
    Header: "Name",
    accessor: "name",
    sort: false,
  },
  {
    Filter: false,
    Header: "Enabled",
    accessor: "enabled",
    sort: false,
  },
  {
    Filter: false,
    Header: "Ip Address",
    accessor: "ip",
    sort: false,
  },
  {
    Filter: false,
    Header: "Edit",
    accessor: "edit",
    sort: false,
  },
];

export const settings_SMTP_SNMP = [
  {
    Filter: false,
    Header: "Name",
    accessor: "name",
    sort: false,
  },
  {
    Filter: false,
    Header: "Enabled",
    accessor: "enabled",
    sort: false,
  },
  {
    Filter: false,
    Header: "Ip Address",
    accessor: "ip",
    sort: false,
  },
  {
    Filter: false,
    Header: "Community",
    accessor: "community",
    sort: false,
  },
  {
    Filter: false,
    Header: "Edit",
    accessor: "edit",
    sort: false,
  },
];

export const settings_HTTP = [
  {
    Filter: false,
    Header: "Name",
    accessor: "name",
    sort: false,
  },
  {
    Filter: false,
    Header: "Enabled",
    accessor: "enabled",
    sort: false,
  },
  {
    Filter: false,
    Header: "Server URL",
    accessor: "url",
    sort: false,
  },
  {
    Filter: false,
    Header: "Auth",
    accessor: "auth",
    sort: false,
  },
  {
    Filter: false,
    Header: "Alerts Update",
    accessor: "alerts",
    sort: false,
  },
  {
    Filter: false,
    Header: "SSL enable",
    accessor: "ssl_enable",
    sort: false,
  },
  {
    Filter: false,
    Header: "SSL Verify",
    accessor: "ssl_verify",
    sort: false,
  },
  {
    Filter: false,
    Header: "Edit",
    accessor: "edit",
    sort: false,
  },
];

export const compressedHeader = [
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: false,
  },
  {
    Filter: false,
    Cell: SeverityStatusWrapper,
    Header: "Verdict",
    accessor: "result",
    sort: false,
  },
];

export const compressedStaticResultsHeader = [
  {
    Filter,
    Header: "File Name / Hash / Type",
    accessor: "file.name",
    rowClassName: "dashboard-table_link",
    Cell: File,
    sort: false,
  },
  {
    Filter: false,
    Cell: (props) => <SeverityStatusWrapper {...props} statusType="staticPopup" />,
    Header: "Static Verdict",
    accessor: "result",
    sort: false,
  },
];

export const networkDetectionsHeader = [
  {
    Filter,
    Header: "Alert Type",
    accessor: "alertType",
    rowClassName: "network-detections__table__alert-type",
    sort: true,
  },
  {
    Filter,
    Header: "Time",
    accessor: "logger_time.$date",
    rowClassName: "network-detections__table__time",
    Cell: NetworkDetectionsTime,
    sort: true,
  },
  {
    Filter,
    Header: "User",
    accessor: "log.username",
    rowClassName: "network-detections__table__username",
    Cell: User,
    sort: true,
  },
  // {
  //   Filter,
  //   Header: "Severity",
  //   accessor: "severity",
  //   rowClassName: "network-detections__table__severity",
  //   Cell: NetworkDetectionsSeverity,
  //   sort: true,
  // },
  {
    Filter,
    Header: "Client IP",
    accessor: "log.clientIp",
    rowClassName: "network-detections__table__client-ip",
    Cell: NetworkDetectionsClientIp,
    sort: true,
  },
  {
    Filter,
    Header: "Server IP",
    accessor: "log.serverIp",
    rowClassName: "network-detections__table__server-ip",
    Cell: NetworkDetectionsServerIp,
    sort: true,
  },
  {
    Filter,
    Header: "File",
    accessor: "file_name",
    rowClassName: "network-detections__table__file-name",
    Cell: NetworkDetectionsFileName,
    sort: true,
  },
  {
    Filter,
    Header: "Url",
    accessor: "log.url",
    rowClassName: "network-detections__table__url",
    Cell: NetworkDetectionsUrl,
    sort: true,
  },
  {
    Filter,
    Header: "Action",
    accessor: "action",
    rowClassName: "network-detections__table__action",
    Cell: NetworkDetectionsAction,
    sort: true,
  },
];

export const urlsHeader = [
  {
    Filter,
    Header: "File Name",
    accessor: "file_name",
    rowClassName: "urls__table__file-name",
    Cell: UrlsFileName,
    sort: true,
  },
  {
    Filter,
    Header: "File Hash",
    accessor: "file_hash",
    rowClassName: "urls__table__file-hash",
    Cell: UrlsFileHash,
    sort: true,
  },
  {
    Filter,
    Header: "Report Date",
    accessor: "report_date",
    rowClassName: "urls__table__report-date",
    sort: true,
  },
  {
    Filter,
    Header: "Server IP",
    accessor: "server_ip",
    rowClassName: "urls__table__server-ip",
    sort: true,
  },
  {
    Filter,
    Header: "Url",
    accessor: "url",
    rowClassName: "urls__table__url",
    Cell: UrlsUrl,
    sort: true,
  },
];

export const domainsAndIpsHeader = [
  {
    Filter,
    Header: "File Name",
    accessor: "file_name",
    rowClassName: "domains__table__file-name",
    Cell: UrlsFileName,
    sort: true,
  },
  {
    Filter,
    Header: "File Hash",
    accessor: "file_hash",
    rowClassName: "domains__table__file-hash",
    Cell: UrlsFileHash,
    sort: true,
  },
  {
    Filter,
    Header: "Report Date",
    accessor: "report_date",
    rowClassName: "domains__table__report-date",
    sort: true,
  },
  {
    Filter,
    Header: "Server IP",
    accessor: "server_ip",
    rowClassName: "domains__table__server-ip",
    sort: true,
  },
  {
    Filter,
    Header: "Domain",
    accessor: "domain",
    rowClassName: "domains__table__domain",
    Cell: DomainsAndIpsDomain,
    sort: true,
  },
];

export const hostsAndDomains = [
  {
    Filter,
    Header: "Server IP",
    accessor: "server_ip",
    className: "hosts-and-domains__table__server-ip",
    sort: true,
  },
  {
    Filter,
    Header: "Host/Domain",
    accessor: "domain",
    className: "hosts-and-domains__table__domain",
    Cell: HostAndDomain,
    sort: true,
  },
  {
    Filter,
    Header: "Country Name",
    accessor: "country_name",
    className: "hosts-and-domains__table__country-name",
    sort: true,
  },
];
