import { createSlice } from "@reduxjs/toolkit";
import { saveToLocalStorage, loadFromLocalStorage } from "../../utils/localStorage";

const analysisReportsSlice = createSlice({
  name: "analysisReports",
  initialState: {
    loading: false,
    general: [],
    loadingGeneral: false,
    signatureList: [],
    loadingSignatureList: false,
    p2signatureList: [],
    p2signaturesloading: false,
    networkActivityMap: [],
    snortSuricataAlerts: [],
    snortSuricataLoading: false,
    attackMapSignature: [],
    chatGPTLoading: false,
    signaturesLoading: false,
    error: null,
    chatGPTError: null,
    reports: [],
    filesInfo: [],
    getVm: null,
    attackMapTree: [],
    mitre: [],
    getComment: [],
    extractedPayloads: [],
    extractedPayloadsLoading: false,
    extractedPayloadsError: null,
    droppedFiles: [],
    droppedFilesLoading: false,
    droppedFilesError: null,
    memoryDumps: [],
    memoryDumpsLoading: false,
    memoryDumpsError: null,
    pcapFile: {},
    pcapFileLoading: false,
    pcapFileError: null,
    staticInfoList: [],
    chatGPT: [],
    fileDetails: [],
    extractedStrings: [],
    attackMapCallsLoading: false,
    attackMapCallsError: null,
    attackMapCalls: [],
    attackMapCallsByCategoryLoading: false,
    attackMapCallsByCategoryError: null,
    attackMapCallsByCategory: [],
    attackMapSummaryLoading: false,
    attackMapSummaryError: null,
    attackMapSummary: {},
    attackMapEnhancedLoading: false,
    attackMapEnhancedError: null,
    attackMapEnhanced: [],
    stopMouseEmulationLoading: false,
    liveUploadFileLoading: false,
    finishAnalysis: null,
    finishAnalysisLoading: false,
    finishAnalysisError: null,
    memoryAddressMapping: [],
    memoryAddressMappingLoading: false,
    memoryAddressMappingError: null,
    readyStatus: "False",
    startAnalysisLoading: false,
    startMouseEmulationLoading: false,
    takeScreenshotLoading: false,
    downloadFileLoading: false,
    checkNetworkConnectionLoading: false,
    increaseRuntime: null,
    increaseRuntimeLoading: false,
    increaseRuntimeError: null,
    enableClipboardLoading: false,
    htmlReport: null,
    htmlReportLoading: false,
    htmlReportError: null,
    artifactSubmissionLoading: false,
    artifactSubmissionError: null,
    reportLogo: "",
    iocUrls: [],
    iocUrlsLoading: false,
    iocDomainsAndIps: [],
    iocDomainsAndIpsLoading: false,
    loadingConvert: false,
    staticFindings: [],
    staticFindingsLoading: false,
    staticFindingsError: null,
    hostsAndDomains: [],
    hostsAndDomainsLoading: false,
    screenshots: [],
    screenshotsLoading: false,
    preStaticResults: {},
    iocs: {},
    iocsLoading: false,
  },
  reducers: {
    RequestGeneralAnalysis: (state) => {
      state.loadingGeneral = true;
    },
    SuccessGeneralAnalysis: (state, action) => {
      state.loadingGeneral = false;
      state.general = action.payload;
      // saveToLocalStorage("general", action.payload);
    },
    FailureGeneralAnalysis: (state, action) => {
      state.loadingGeneral = false;
      state.error = action.payload;
    },
    RequestGetSignatures: (state) => {
      state.loadingSignatureList = true;
    },
    SuccessGetSignatures: (state, action) => {
      state.loadingSignatureList = false;
      state.signatureList = action.payload.data;
      state.attackMapSignature = action.payload.data;
      // saveToLocalStorage("signatureList", action.payload.data);
      // saveToLocalStorage("attackMapSignature", action.payload.data);
    },
    FailureGetSignatures: (state, action) => {
      state.loadingSignatureList = false;
      state.error = action.payload;
    },
    RequestNetwork: (state) => {
      state.snortSuricataLoading = true;
    },
    SuccessNetwork: (state, action) => {
      state.snortSuricataAlerts = action.payload.snort_suricata_alerts;
      state.networkActivityMap = action.payload.network_activity;
      state.snortSuricataLoading = false;
      // saveToLocalStorage("snortSuricataAlerts", action.payload.snort_suricata_alerts);
      // saveToLocalStorage("dnsIps", action.payload.dns_ips);
      // saveToLocalStorage("networkActivityMap", action.payload.network_activity);
    },
    FailureNetwork: (state) => {
      state.snortSuricataLoading = false;
    },
    RequestFilesInfo: (state) => {
      state.loading = true;
    },
    SuccessFilesInfo: (state, payload) => {
      state.loading = false;
      state.filesInfo = payload.payload;
    },
    FailureFilesInfo: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestReadyStatus: () => {},
    SuccessReadyStatus: (state, action) => {
      state.readyStatus = action.payload;
    },
    ClearReadyStatus: (state) => {
      state.readyStatus = "False";
    },
    RequestGetVm: (state) => {
      state.getVm = null;
      state.loading = true;
    },
    SuccessGetVm: (state, payload) => {
      state.loading = false;
      state.getVm = payload.payload;
    },
    FailureGetVm: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestAttackMapTree: (state) => {
      state.loading = true;
    },
    SuccessAttackMapTree: (state, payload) => {
      state.loading = false;
      state.attackMapTree = payload.payload;
    },
    FailureAttackMapTree: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestMitreAttack: (state) => {
      state.loading = true;
    },
    SuccessMitreAttack: (state, payload) => {
      state.loading = false;
      state.mitre = payload.payload;
    },
    FailureMitreAttack: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestGetComment: (state) => {
      state.loading = true;
    },
    SuccessGetComment: (state, payload) => {
      state.loading = false;
      state.getComment = payload.payload;
    },
    FailureGetComment: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestAddComment: (state) => {
      state.loading = true;
    },
    SuccessAddComment: (state) => {
      state.loading = false;
    },
    FailureAddComment: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestExtractedPayloads: (state) => {
      state.extractedPayloads = [];
      state.extractedPayloadsError = null;
      state.extractedPayloadsLoading = true;
    },
    SuccessExtractedPayloads: (state, action) => {
      state.extractedPayloads = action.payload;
      state.extractedPayloadsLoading = false;
    },
    FailureExtractedPayloads: (state, action) => {
      state.extractedPayloads = [];
      state.extractedPayloadsError = action.payload;
      state.extractedPayloadsLoading = false;
    },
    RequestDroppedFiles: (state) => {
      state.droppedFiles = [];
      state.droppedFilesError = null;
      state.droppedFilesLoading = true;
    },
    SuccessDroppedFiles: (state, action) => {
      state.droppedFiles = action.payload;
      state.droppedFilesLoading = false;
    },
    FailureDroppedFiles: (state, action) => {
      state.droppedFiles = [];
      state.droppedFilesError = action.payload;
      state.droppedFilesLoading = false;
    },
    RequestMemoryDumps: (state) => {
      state.memoryDumps = [];
      state.memoryDumpsError = null;
      state.memoryDumpsLoading = true;
    },
    SuccessMemoryDumps: (state, action) => {
      state.memoryDumps = action.payload;
      state.memoryDumpsLoading = false;
    },
    FailureMemoryDumps: (state, action) => {
      state.memoryDumps = [];
      state.memoryDumpsError = action.payload;
      state.memoryDumpsLoading = false;
    },
    RequestPcapFile: (state) => {
      state.pcapFile = {};
      state.pcapFileError = null;
      state.pcapFileLoading = true;
    },
    SuccessPcapFile: (state, action) => {
      state.pcapFile = action.payload;
      state.pcapFileLoading = false;
    },
    FailurePcapFile: (state, action) => {
      state.pcapFile = {};
      state.pcapFileError = action.payload;
      state.pcapFileLoading = false;
    },
    RequestP2signatureList: (state) => {
      state.p2signaturesloading = true;
      state.p2signatureList = [];
    },
    SuccessP2signatureList: (state, action) => {
      state.p2signaturesloading = false;
      state.p2signatureList = action.payload;
      // saveToLocalStorage("p2signatureList", action.payload);
    },
    FailureP2signatureList: (state) => {
      state.p2signaturesloading = false;
    },
    RequestDeleteComment: (state) => {
      state.loading = true;
    },
    SuccessDeleteComment: (state) => {
      state.loading = false;
    },
    FailureDeleteComment: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    RequestEditComment: (state) => {
      state.loading = true;
    },
    SuccessEditComment: (state) => {
      state.loading = false;
    },
    FailureEditComment: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    RequestStaticInfo: (state) => {
      state.loading = true;
    },
    SuccessStaticInfo: (state, payload) => {
      state.loading = false;
      state.staticInfoList = payload.payload;
    },
    FailureStaticInfo: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestChatGPT: (state) => {
      state.chatGPTLoading = true;
      state.chatGPTError = null;
    },
    SuccessChatGPT: (state, payload) => {
      state.chatGPTLoading = false;
      state.chatGPT = payload.payload;
      state.chatGPTError = null;
    },
    FailureChatGPT: (state, payload) => {
      state.chatGPTLoading = false;
      state.chatGPTError = payload.payload;
    },
    RequestFileDetails: (state) => {
      state.loading = true;
    },
    SuccessFileDetails: (state, payload) => {
      state.loading = false;
      state.fileDetails = payload.payload;
    },
    FailureFileDetails: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestExtractedStrings: (state) => {
      state.loading = true;
    },
    SuccessExtractedStrings: (state, payload) => {
      state.loading = false;
      state.extractedStrings = payload.payload;
    },
    FailureExtractedStrings: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestAttackMapCalls: (state) => {
      state.attackMapCallsError = null;
      state.attackMapCallsLoading = true;
    },
    SuccessAttackMapCalls: (state, payload) => {
      state.attackMapCallsLoading = false;
      state.attackMapCalls = payload.payload;
    },
    FailureAttackMapCalls: (state, payload) => {
      state.attackMapCallsLoading = false;
      state.attackMapCallsError = payload.payload;
    },
    RequestAttackMapCallsByCategory: (state) => {
      state.attackMapCallsByCategoryError = null;
      state.attackMapCallsByCategoryLoading = true;
    },
    SuccessAttackMapCallsByCategory: (state, payload) => {
      state.attackMapCallsByCategoryLoading = false;
      state.attackMapCallsByCategory = payload.payload;
    },
    FailureAttackMapCallsByCategory: (state, payload) => {
      state.attackMapCallsByCategoryLoading = false;
      state.attackMapCallsByCategoryError = payload.payload;
    },
    RequestAttackMapSummary: (state) => {
      state.attackMapSummaryError = null;
      state.attackMapSummaryLoading = true;
    },
    SuccessAttackMapSummary: (state, payload) => {
      state.attackMapSummaryLoading = false;
      state.attackMapSummary = payload.payload;
    },
    FailureAttackMapSummary: (state, payload) => {
      state.attackMapSummaryLoading = false;
      state.attackMapSummaryError = payload.payload;
    },
    RequestAttackMapEnhanced: (state) => {
      state.attackMapEnhancedError = null;
      state.attackMapEnhancedLoading = true;
    },
    SuccessAttackMapEnhanced: (state, payload) => {
      state.attackMapEnhancedLoading = false;
      state.attackMapEnhanced = payload.payload;
    },
    FailureAttackMapEnhanced: (state, payload) => {
      state.attackMapEnhancedLoading = false;
      state.attackMapEnhancedError = payload.payload;
    },
    RequestStopMouseEmulation: (state) => {
      state.stopMouseEmulationLoading = true;
    },
    SuccessStopMouseEmulation: (state) => {
      state.stopMouseEmulationLoading = false;
    },
    FailureStopMouseEmulation: (state) => {
      state.stopMouseEmulationLoading = false;
    },
    RequestLiveUploadFile: (state) => {
      state.liveUploadFileLoading = true;
    },
    SuccessLiveUploadFile: (state) => {
      state.liveUploadFileLoading = false;
    },
    FailureLiveUploadFile: (state) => {
      state.liveUploadFileLoading = false;
    },
    RequestFinishAnalysis: (state) => {
      state.finishAnalysisError = null;
      state.finishAnalysisLoading = true;
    },
    SuccessFinishAnalysis: (state, action) => {
      state.finishAnalysisLoading = false;
      state.finishAnalysis = action.payload;
    },
    FailureFinishAnalysis: (state, action) => {
      state.finishAnalysisLoading = false;
      state.finishAnalysisError = action.payload;
    },
    RequestMemoryAddressMapping: (state) => {
      state.memoryAddressMapping = [];
      state.memoryAddressMappingError = null;
      state.memoryAddressMappingLoading = true;
    },
    SuccessMemoryAddressMapping: (state, action) => {
      state.memoryAddressMapping = action.payload;
      state.memoryAddressMappingLoading = false;
    },
    FailureMemoryAddressMapping: (state, action) => {
      state.memoryAddressMappingLoading = false;
      state.memoryAddressMappingError = action.payload;
    },
    RequestStartAnalysis: (state) => {
      state.startAnalysisLoading = true;
    },
    SuccessStartAnalysis: (state) => {
      state.startAnalysisLoading = false;
    },
    FailureStartAnalysis: (state) => {
      state.startAnalysisLoading = false;
    },
    RequestStartMouseEmulation: (state) => {
      state.startMouseEmulationLoading = true;
    },
    SuccessStartMouseEmulation: (state) => {
      state.startMouseEmulationLoading = false;
    },
    ErrorStartMouseEmulation: (state) => {
      state.startMouseEmulationLoading = false;
    },
    RequestTakeScreenshot: (state) => {
      state.takeScreenshotLoading = true;
    },
    SuccessTakeScreenshot: (state) => {
      state.takeScreenshotLoading = false;
    },
    FailureTakeScreenshot: (state) => {
      state.takeScreenshotLoading = false;
    },
    RequestDownloadFile: (state) => {
      state.downloadFileLoading = true;
    },
    SuccessDownloadFile: (state) => {
      state.downloadFileLoading = false;
    },
    FailureDownloadFile: (state) => {
      state.downloadFileLoading = false;
    },
    RequestCheckNetworkConnection: (state) => {
      state.checkNetworkConnectionLoading = true;
    },
    SuccessCheckNetworkConnection: (state) => {
      state.checkNetworkConnectionLoading = false;
    },
    FailureCheckNetworkConnection: (state) => {
      state.checkNetworkConnectionLoading = false;
    },
    RequestEnableClipboard: (state) => {
      state.enableClipboardLoading = true;
    },
    SuccessEnableClipboard: (state) => {
      state.enableClipboardLoading = false;
    },
    FailureEnableClipboard: (state) => {
      state.enableClipboardLoading = false;
    },
    RequestIncreaseRuntime: (state) => {
      state.increaseRuntimeError = null;
      state.increaseRuntimeLoading = true;
    },
    SuccessIncreaseRuntime: (state, action) => {
      state.increaseRuntimeLoading = false;
      state.increaseRuntime = action.payload;
    },
    FailureIncreaseRuntime: (state, action) => {
      state.increaseRuntimeLoading = false;
      state.increaseRuntimeError = action.payload;
    },
    RequestGetHtmlReport: (state) => {
      state.htmlReportError = null;
      state.htmlReportLoading = true;
    },
    SuccessGetHtmlReport: (state, action) => {
      state.htmlReportError = null;
      state.htmlReportLoading = false;

      state.htmlReport = action.payload;
    },
    FailureGetHtmlReport: (state, action) => {
      state.htmlReportLoading = false;
      state.htmlReportError = action.payload;
    },
    RequestArtifactSubmission: (state) => {
      state.artifactSubmissionError = null;
      state.artifactSubmissionLoading = true;
    },
    SuccessArtifactSubmission: (state) => {
      state.artifactSubmissionError = null;
      state.artifactSubmissionLoading = false;
    },
    FailureArtifactSubmission: (state, action) => {
      state.artifactSubmissionLoading = false;
      state.artifactSubmissionError = action.payload;
    },
    RequestGetReportLogo: (state) => {
      state.loading = true;
    },
    SuccessGetReportLogo: (state, action) => {
      state.reportLogo = action.payload;
    },
    FailureGetReportLogo: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    RequestSetReportLogo: (state) => {
      state.loading = true;
    },
    SuccessSetReportLogo: (state) => {
      state.loading = false;
    },
    FailureSetReportLogo: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    RequestIocUrls: (state) => {
      state.iocUrlsLoading = true;
    },
    SuccessIocUrls: (state, action) => {
      state.iocUrlsLoading = false;
      state.iocUrls = action.payload;
    },
    FailureIocUrls: (state) => {
      state.iocUrlsLoading = false;
    },
    RequestIocDomainsAndIps: (state) => {
      state.iocDomainsAndIpsLoading = true;
    },
    SuccessIocDomainsAndIps: (state, action) => {
      state.iocDomainsAndIpsLoading = false;
      state.iocDomainsAndIps = action.payload;
    },
    FailureIocDomainsAndIps: (state) => {
      state.iocDomainsAndIpsLoading = false;
    },
    convertNewSystemRequest: (state) => {
      state.loadingConvert = true;
    },
    convertNewSystemSuccess: (state) => {
      state.loadingConvert = false;
    },
    convertNewSystemFailure: (state, payload) => {
      state.loadingConvert = false;
      state.error = payload?.payload;
    },
    RequestStaticFindings: (state) => {
      state.staticFindingsLoading = true;
      state.staticFindingsError = null;
    },
    SuccessStaticFindings: (state, action) => {
      state.staticFindingsLoading = false;
      state.staticFindings = action.payload;
    },
    FailureStaticFindings: (state, action) => {
      state.staticFindingsLoading = false;
      state.staticFindingsError = action.payload;
      state.staticFindings = [];
    },
    RequestHostsAndDomains: (state) => {
      state.hostsAndDomainsLoading = true;
    },
    SuccessHostsAndDomains: (state, action) => {
      state.hostsAndDomainsLoading = false;
      state.hostsAndDomains = action.payload;
    },
    FailureHostsAndDomains: (state) => {
      state.hostsAndDomainsLoading = false;
      state.hostsAndDomains = [];
    },
    RequestScreenshots: (state) => {
      state.screenshotsLoading = true;
    },
    SuccessScreenshots: (state, action) => {
      state.screenshotsLoading = false;
      state.screenshots = action.payload;
    },
    FailureScreenshots: (state) => {
      state.screenshotsLoading = false;
      state.screenshots = [];
    },
    RequestGetPreStaticResults: (state, { payload }) => {
      const { id } = payload;
      state.preStaticResults = {
        ...state.preStaticResults,
        [id]: { loading: true, error: null },
      };
    },
    SuccessGetPreStaticResults: (state, action) => {
      state.preStaticResults = {
        ...state.preStaticResults,
        [action.payload.id]: { loading: false, error: null },
      };
    },
    FailureGetPreStaticResults: (state, action) => {
      state.preStaticResults = {
        ...state.preStaticResults,
        [action.payload.id]: { loading: false, error: action.payload.error },
      };
    },
    ResetPreStaticResults: (state) => {
      state.preStaticResults = {};
    },
    RequestIocs: (state) => {
      state.iocsLoading = true;
    },
    SuccessIocs: (state, action) => {
      state.iocsLoading = false;
      state.iocs = action.payload;
    },
    FailureIocs: (state) => {
      state.iocsLoading = false;
    },
  },
});

export default analysisReportsSlice;
