import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Backdrop from "../../UI/Backdrop/Backdrop";
import PreStaticResultsTableDetails from "./Components/PreStaticResultsTableDetails";
import CustomTable from "../Table/CustomTable";
import { compressedStaticResultsHeader } from "../../../utils/constants/tableHeaders";
import { RequestGetPreStaticResults } from "../../../store/actions/analysisReportActions";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PreStaticResultsTable = ({ tableName }) => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state?.dashboard?.list);
  const submissionsListLoading = useSelector((state) => state?.dashboard?.submissionsListLoading);

  let query = useQuery();

  const fileID = query.get("data");
  const staticResultRequestState =
    useSelector((state) => state?.analysisReports?.preStaticResults[fileID]) || {};

  const detailID = query.get("details") || fileID;
  const listItem = list?.find((filterData) => filterData.fileID === detailID) || {};
  const listItemStaticDecision = listItem?.static?.decision || null;

  useEffect(() => {
    if ((listItemStaticDecision === null || [99, 100].includes(listItemStaticDecision)) && fileID) {
      dispatch(RequestGetPreStaticResults({ id: fileID }));
    }
  }, [fileID, listItemStaticDecision, dispatch]);

  if (submissionsListLoading) {
    <div className="pre-static-table-wrapper">
      <Backdrop absolute={true} color="transparent" show={submissionsListLoading} />
    </div>;
  }

  return (
    <>
      {tableName === "compressedStaticResults" ? (
        <CustomTable
          column={compressedStaticResultsHeader}
          tableName={tableName}
          leftAction={false}
          tableFolder={tableName}
          isSetUrl
          paginationButtons={false}
          className=""
          listGroupData={listItem ? listItem.compressedIDfull : ""}
          refresh={false}
          customPageSize={200}
          minHeight={50}
        />
      ) : tableName === "staticResults" ? (
        <div className="pre-static-table-wrapper">
          <PreStaticResultsTableDetails
            isLoading={staticResultRequestState?.loading || submissionsListLoading}
            state={staticResultRequestState}
            details={listItem ? listItem.static : {}}
          />
        </div>
      ) : null}
    </>
  );
};

export default PreStaticResultsTable;
